import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ImgCacao from "../images/404.png"


export const StyledText = styled.div`
  display: inline-block;
  max-width: 32rem;
`

export const StyledHeading = styled.h2`
  line-height: 1.125em;
  letter-spacing: -0.025em;
  color: rgb(25, 28, 31);
  font-size: 2.5rem;
  margin: 0px;

  @media (min-width: 1081px) {
    font-size: 3rem;
  }
`

export const StyledImage = styled.div`
  @media (max-width: 1080px) {
    margin-top: 6rem;
    max-height: 32rem;
  }
`

export const StyledAnimation = styled.div`
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const StyledSlide = styled.div`
  padding: 0px 3rem;
  margin: auto;
  max-width: 86rem;
  min-height: 100vh;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  flex-direction: column;
  color: #FF5F00;

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
    max-height: none;
  }

  @media (max-width: 1080px) {
    margin: 70px 0px;
    flex-direction: column-reverse;
    -moz-box-pack: center !important;
    justify-content: center !important;
    -moz-box-align: center !important;
    align-items: center !important;
    max-height: none;

    &:first-child {
      margin-top: auto;
    }

    & > div:first-child {
      margin-right: 0px;
    }

    & h2,
    & p {
      text-align: center;
    }
  }

  @media (min-width: 1081px) {
    ${ props => props.reverse ? "flex-direction: column-reverse" : "" };
  }
`

const HTTPNotFoundPage = () => (
  <Layout>
    <SEO title="404 Not Found" />
    <StyledSlide reverse={true}>
      <StyledText>
        <h2>
          ¡Ups! Parece que te perdiste
        </h2>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgCacao} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
  </Layout>
)

export default HTTPNotFoundPage
